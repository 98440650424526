<template>
<div class="modal-card modal-content-width">
    <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Config. de refinanciamiento vencido</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
    </section>
    <section class="modal-card-body has-background-white text-left">
        <div class="flex">
            <div>
                <p class="text-lg font-semibold" :class="{'has-text-grey': noPuedeRefinanciar}">Refinanciar saldo vencido</p>
                <p :class="{'has-text-grey': noPuedeRefinanciar}">Para activar la funcion se requiere que la disposicion activa y pasiva tengan saldo (capital).</p>
            </div>
            <b-switch 
                :value="getRefinanciamientoVencido == 1 ? true : false"
                :disabled="noPuedeRefinanciar"
                @input="toggleRefinanciamiento"
                type="is-info">
            </b-switch>
        </div>
        <div v-if="noPuedeRefinanciar" class="mt-6">
            <p class="font-bold text-red-600">Función desactivada debido a que:</p>
            <p v-if="getCredito.saldos.capital.valor < 1" class="text-red-600">- La disposición activa no tiene saldo (capital).</p>
            <p v-if="getCredito.credito_pasivo.saldos.capital.valor < 1" class="text-red-600">- La disposición pasiva no tiene saldo (capital).</p>
        </div>
    </section>
    <section class="modal-card-foot">
        
      </section>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ModalRefinanciamientoVencido",
    data() {
        return{
            noPuedeRefinanciar: this.getCredito?.saldos.capital.valor < 1 || this.getCredito?.credito_pasivo?.saldos.capital.valor < 1
        }
    },
    props: {
        disposicion: Object
    },
    computed: {
        ...mapGetters("disposicion", ["getRefinanciamientoVencido", "getCredito"])
    },
    methods: {
        toggleRefinanciamiento(){
            this.$store.dispatch('disposicion/toggleRefinanciamientoVencido', this.$route.params.idDisposicion)
        }
    },
    mounted() {
        this.$store.dispatch('disposicion/getRefinanciamientoVencido', this.$route.params.idDisposicion);
    }
}
</script>