<template>
  <div>
    <div class="text-right" v-if="getDisposicionDetalle.credito_status_id !== 5 && getDisposicionDetalle.pasiva == 0">
      <b-button
        class="mb-4"
        :type="'is-pasivo'"
        @click="fondearDisposicion"
      >
        Fondear disposición
      </b-button>
    </div>
    <bienvenido-card
      :pasiva="1"
      :titulo="getDisposicionDetalle.pasiva == 0 ? 'Aquí está tu lista de fondeos' : 'Aqui esta tu lista de disposiciones fondeadas'"
      :descripcion="getDisposicionDetalle.pasiva == 0 
        ? 'Todos los fondeos que se hayan hecho en esta disposición aparecerán aquí como una disposición pasiva. Da click en detalle para ir al detalle de disposición pasiva o fondear disposición en el botón de abajo.'
        : 'Todas las disposiciones que se hayan fondeado con esta disposición aparecerán aqui como una disposición activa. Da click en detalle para ir al detalle de disposición activa.'"
      img="/images/fondeos.png"
    />
    <div v-if="getDisposicionDetalle.pasiva == 0" class="columns is-multiline mt-4">
      <div class="column is-12" v-for="pasiva in getPasivas" :key="pasiva.id">
        <disposicion-pasiva-card :disposicion="pasiva" />
      </div>
    </div>
    <div v-else class="columns is-multiline mt-4">
      <div class="column is-12" v-for="activa in getActivas" :key="activa.id">
        <disposicion-card :disposicion="activa" />
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="is_fondear_disp && getDisposicionDetalle.pasiva == 0"
        :active.sync="is_fondear_disp"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-fondear-disposicion
          class="modal-has-overflow"
          :disposicion="getDisposicionDetalle"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import ModalFondearDisposicion from "@/components/modals/ModalFondearDisposicion";
import BienvenidoCard from "@/components/cards/BienvenidoCard";
import DisposicionPasivaCard from "@/components/cards/DisposicionPasivaCard";
import DisposicionCard from "@/components/cards/DisposicionCard";
import { mapGetters } from "vuex";

export default {
  name: "Fondeos",
  components: {
    ModalFondearDisposicion,
    BienvenidoCard,
    DisposicionPasivaCard,
    DisposicionCard,
  },
  data() {
    return {
      is_fondear_disp: false
    };
  },
  methods: {
    fondearDisposicion() {
      this.is_fondear_disp = true;
    }
  },
  computed: {
    ...mapGetters("disposicion", ["getPasivas", "getActivas", "getDisposicionDetalle"])
  },
  mounted() {
    const action = this.getDisposicionDetalle.pasiva == 0 ? 'Pasivas' : 'Activas'; 
    this.$store.dispatch(
      `disposicion/get${action}`,
      this.$route.params.idDisposicion
    );
  }
};
</script>
