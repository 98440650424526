<template>
  <div class="column is-12 pt-0 -mt-8 mt-0">
    <div class="columns pt-0">
      <div class="column is-10 pt-0">
        <div class="columns is-mobile">
          <div class="column is-1 text-center text-sm px-0 mt-2">
            {{ fechaEntrega | moment("DD-MM-YYYY") }}
          </div>
          <div class="column px-0">
            <b-field>
              <b-slider
                :min="0"
                :max="between_dates.length - 1"
                v-model="actual_dates"
                class="gradient"
                lazy
                type="is-primary"
                :custom-formatter="val => between_dates[val]"
              />
            </b-field>
          </div>
          <div class="column is-1 text-center text-sm px-0 mt-2">
            {{ fecha_proyeccion | moment("DD-MM-YYYY") }}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <label for="">Seleccionar día</label>
        <valid-datepicker
          rules="none"
          placeholder="seleccionar día..."
          v-model="fecha"
          :min-date="min_date"
          position="is-bottom-left"
          :append-to-body="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ValidDatepicker from "@/components/form/ValidDatepicker";
import { mapGetters } from "vuex";
import { debounce } from "@/vendors/helpers";

export default {
  name: "Timeline",
  components: {
    ValidDatepicker
  },
  props: {
    fechaEntrega: Date,
    fechaActiva: {
      type: Date,
      required: false
    }
  },
  data() {
    return {
      between_dates: [],
      actual_dates: [],
      fecha: null,
      min_date: null,
      max_date: null
    };
  },
  methods: {
    /*
      Calcula los días que existen entre dos fechas en un arreglo para mostrar un slider
      @startDate (Date): fecha de donde empezar a contar
      @endDate (Date): fecha donde terminar de contar
    */
    enumerateDaysBetweenDates(startDate, endDate) {
      let dates = [];
      let currDate = this.$moment(startDate).startOf("day");
      dates.push(currDate.clone().format("DD-MM-YYYY"));
      let lastDate = this.$moment(endDate).startOf("day");
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(currDate.clone().format("DD-MM-YYYY"));
      }
      dates.push(lastDate.clone().format("DD-MM-YYYY"));
      this.between_dates = dates;
    },
    //Obtiene un rango de fechas del día indicado, 3 para delante y 3 para atras
    setActualDates() {
      let actual_date_back = this.$moment(this.getFechaCierre);
      let count = 0;
      while (count < 3) {
        if (!actual_date_back.isAfter(this.$moment(this.fechaEntrega))) {
          break;
        }
        actual_date_back.subtract(1, "d");
        this.actual_dates.push(actual_date_back.clone().format("DD-MM-YYYY"));
        count++;
      }
      let actual_date_front = this.$moment(this.getFechaCierre);
      count = 0;
      while (count < 3) {
        if (!actual_date_front.isBefore(this.fecha_proyeccion)) {
          break;
        }
        actual_date_front.add(1, "d");
        this.actual_dates.push(actual_date_front.clone().format("DD-MM-YYYY"));
        count++;
      }
      this.actual_dates.push(
        this.$moment(this.getFechaCierre)
          .clone()
          .format("DD-MM-YYYY")
      );
      let dates_index = [];
      this.actual_dates.forEach(x => {
        dates_index.push(this.between_dates.indexOf(x));
      });
      this.actual_dates = [
        dates_index.sort((a,b) => a-b)[0],
        dates_index.sort((a,b) => a-b)[dates_index.length - 1]
      ];
      this.min_date = this.$moment(
        this.between_dates[this.actual_dates[0]],
        "DD/MM/YYYY"
      ).toDate();
      this.max_date = this.$moment(
        this.between_dates[this.actual_dates[1]],
        "DD/MM/YYYY"
      ).toDate();
    }
  },
  computed: {
    ...mapGetters(["getFechaCierre", "getSofom"]),
    ...mapGetters("disposicion", ["getOperacionesDiarias"]),
    fecha_proyeccion() {
      return this.$moment(this.getFechaCierre);
    }
  },
  watch: {
    actual_dates: debounce(function(val) {
      this.min_date = this.$moment(
        this.between_dates[val[0]],
        "DD/MM/YYYY"
      ).toDate();
      this.max_date = this.$moment(
        this.between_dates[val[1]],
        "DD/MM/YYYY"
      ).toDate();
      if (this.fecha > this.max_date) {
        this.fecha = this.max_date;
      }
      if (this.fecha < this.min_date) {
        this.fecha = this.min_date;
      }
      this.$emit("fechassetted", {
        min_date: this.min_date,
        max_date: this.max_date
      });
    }, 300),
    fechaActiva: function(val) {
      if (val != null) {
        this.fecha = val;
      }
    },
    fecha: function(val) {
      this.$emit("fechaactualsetted", val);
    }
  },
  mounted() {
    this.enumerateDaysBetweenDates(this.fechaEntrega, this.fecha_proyeccion);
    this.setActualDates();
    this.fecha = this.getFechaCierre;
  }
};
</script>
