<template>
  <div class="columns flex flex-col">
    <radio-group class="ml-10" :buttons="radio_btns" v-model="view_type" />
    <div class="column flex flex-col md:flex-row">
      <div class="column is-8">
        <div
          class="card has-border-radius shadow-none px-4 h-full"
          :class="{ 'flex justify-center': garantias.length == 0 }"
        >
          <div class="columns is-multiline ">
            <div
              class="column is-12"
              v-for="garantia in garantias"
              :key="garantia.id"
            >
              <garantia-card :garantia="garantia" @reclamar="reclamar" />
            </div>
            <div v-if="garantias.length == 0">
              <empty-state
                type="svg"
                imagen="/images/movimientos.svg"
                :titulo="
                  view_type == '0'
                    ? 'No tienes garantias financieras'
                    : 'No tienes garantias de portafolio'
                "
                subtitulo="En esta disposición"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <sidebar
          :disposicion="getDisposicionDetalle"
          tipo="disposicion"
          @toggle-cf="toggleCapvitalFiles"
          @asignar-garantia="asignarGarantia"
          @asignar-garantia-existente="asignarGarantiaExistente"
          :archivos="getArchivosGarantia"
          :pasiva="getDisposicionDetalle.pasiva ? true : false"
          :acreditado="getDisposicionDetalle.acreditado_id"
        />
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_open"
        :active.sync="is_modal_open"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-reclamar-garantia
          :garantia="garantia_reclamar"
          :disposicion_id="$route.params.idDisposicion"
          @reclamada="reloadDisp"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import Sidebar from "@/components/garantias/Sidebar";
import EmptyState from "@/components/generals/EmptyState";
import GarantiaCard from "@/components/cards/GarantiaCard";
import ModalReclamarGarantia from "@/components/modals/ModalReclamarGarantia";
import RadioGroup from "@/components/generals/RadioGroup";
import { mapGetters } from "vuex";

export default {
  name: "Garantias",
  components: {
    Sidebar,
    EmptyState,
    GarantiaCard,
    ModalReclamarGarantia,
    RadioGroup
  },
  data() {
    return {
      view_type: false,
      garantia_reclamar: null,
      is_modal_open: false,
      radio_btns: [
        {
          label: "Financieras",
          icon: "dollar-sign",
          value: false
        },
        {
          label: "Portafolio",
          icon: "university",
          value: true
        }
      ]
    };
  },
  methods: {
    reclamar(data) {
      this.is_modal_open = true;
      this.garantia_reclamar = data;
    },
    reloadDisp() {
      this.$store.dispatch(
        "disposicion/getGarantias",
        this.$route.params.idDisposicion
      );
    },
    toggleCapvitalFiles(status) {
      let data = {
        type: "garantias_disposicion",
        id: this.$route.params.idDisposicion,
        persona: this.getDisposicionDetalle.acreditado,
        status: status
      };
      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
    async asignarGarantiaExistente(garantia_id) {
      this.$store
        .dispatch("disposicion/asignarGarantiaExistente", {
          garantia_id: garantia_id,
          disposicion_id: this.$route.params.idDisposicion
        })
        .then(() => this.reloadGarantias());
    },
    async reloadGarantias() {
      await this.$store.dispatch(
        "disposicion/getGarantias",
         this.$route.params.idDisposicion
      );
    },
    asignarGarantia(form) {
      form.disposicion_id = this.$route.params.idDisposicion;
      this.$store.dispatch("disposicion/asignarGarantia", form);
    }
  },
  computed: {
    ...mapGetters("disposicion", [
      "getGarantias",
      "getDisposicionDetalle",
      "getArchivosGarantia"
    ]),
    garantias() {
      if (this.getGarantias != null && this.getGarantias.length > 0) {
        if (this.view_type == "0") {
          return this.getGarantias.filter(
            x => x.tipos_garantia.parent_tipos_garantia_id == 1
          );
        } else {
          return this.getGarantias.filter(
            x => x.tipos_garantia.parent_tipos_garantia_id == 3
          );
        }
      }
      return [];
    }
  },
  created() {
    this.$store.dispatch(
      "disposicion/getGarantias",
      this.$route.params.idDisposicion
    );
  }
};
</script>
