<template>
  <div class="columns is-multiline">
    <div class="column is-6 text-left flex items-center">
      <p class="text-2xl font-bold">
        Castigos
      </p>
    </div>
    <div class="column is-6 text-right">
      <b-button
        :type="pasivo ? 'is-pasivo' : 'is-primary'"
        icon-left="plus"
        @click="() => (is_modal_castigo = true)"
        >Agregar castigo</b-button
      >
    </div>
    <div class="column is-12 text-left">
      <b-table :data="getCastigo || []">
        <b-table-column field="notas" label="Notas" v-slot="props">
          {{ props.row.notas }}
        </b-table-column>
        <b-table-column field="fecha" label="Fecha" v-slot="props">
          {{ props.row.fecha_castigo | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column
          field="cantidad"
          label="Valor"
          cell-class="text-right"
          header-class="text-right"
          v-slot="props"
          >{{ props.row.monto_castigo | currency | hp }}
          <!-- {{ props.row.cuenta_c_p.moneda.clave }} -->
        </b-table-column>
        <template slot="empty">
          <empty-state
            type="svg"
            imagen="/images/cuentas_cp.svg"
            text-class="items-baseline"
            :titulo="'No hay castigos'"
            subtitulo="Agrega uno en el botón de arriba a la derecha."
          />
        </template>
      </b-table>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_castigo"
        :active.sync="is_modal_castigo"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-castigo-disposicion
          class="modal-has-overflow"
          :disposicionId="disposicionId"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";
import ModalCastigoDisposicion from "@/components/modals/ModalCastigoDisposicion";

export default {
  name: "DisposicionCastigos",
  components: {
    EmptyState,
    ModalCastigoDisposicion,
  },
  data() {
    return {
        is_modal_castigo: false,
    }
  },
  props: {
    disposicionId: Number,
    pasivo: {
        type: Boolean,
        default: false,
    },
  },
  computed: {
    ...mapGetters("disposicion", ["getCastigo"])
  },
  async mounted() {
    await this.$store.dispatch(
      "disposicion/getCastigos",
      this.disposicionId
    );
  }
};
</script>