<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ validate }"
        class="w-full text-left"
    >
        <div class="modal-card modal-content-width">
            <section class="modal-card-head pb-0">
                <p class="font-bold text-2xl">Generar rompimiento</p>
                <button
                type="button"
                class="delete absolute right-0 mr-5"
                @click="$parent.close()"
                />
            </section>
            <section class="modal-card-body has-background-white text-left">
                <div class="form-container p-1">
                    <ValidSelect
                        rules="required"
                        label="Producto financiero para la nueva línea"
                        fieldClass="label-big"
                        placeholder="Selecciona un producto financiero..."
                        v-model="producto_financiero_id"
                        :data="productos_financieros"
                        show="alias"
                        realValue="id"
                        :expanded="true"
                    />
                </div>
            </section>
            <section class="modal-card-foot justify-end">
                <b-button
                    type="is-primary"
                    @click="validate().then(result => generarRompimiento(result))"
                >
                    Generar
                </b-button>
        </section>
        </div>
    </ValidationObserver>
</template>
    
<script>
    import { mapGetters } from "vuex";
    import { ValidationObserver } from "vee-validate";
    
    import ValidSelect from "@/components/form/ValidSelect";

    import GrupoCreditoApi from "@/api/grupoCredito.api";
    
    export default {
        name: "ModalRompimiento",
        components: {
            ValidationObserver,
            ValidSelect
        },
        data() {
            return {
                producto_financiero_id: null,
            }
        },
        props: {
            disposicion_id: Number,
        },
        computed: {
            ...mapGetters("catalogos", ["getProductosFinancieros"]),

            productos_financieros() {
                return this.getProductosFinancieros.filter(producto => !producto.tipo_credito.tipo.includes('Grupal'));
            }
        },
        methods: {
            generarRompimiento(validated) {
                if (! validated) return;

                GrupoCreditoApi.rompimiento({
                    disposicion_id: this.disposicion_id, 
                    producto_financiero_id: this.producto_financiero_id
                })
                .then(res => {
                    this.$router.push('/linea/' + res.data.id);
                });
            }
        },
        async created() {
            if (this.getProductosFinancieros.length == 0) {
                await this.$store.dispatch("catalogos/getProductosFinancieros");
            }
        }
    }
</script>