<template>
  <div
    class="card h-full has-background-white-ter has-border-radius p-3 px-5 text-left shadow-none flex flex-col justify-between"
  >
    <div class="flex my-1">
      <b-icon
        icon="file-contract"
        class="has-text-primary has-background-primary-02 rounded-full mt-2"
      />
      <div class="ml-4">
        <p class="font-medium has-text-grey-darker text-md">
          Contrato
        </p>
        <p class="has-text-grey font-bold text-sm">
          {{
            linea.length != 0 && linea[0].contrato_linea
              ? linea[0].contrato_linea.numero
              : "No definido"
          }}
        </p>
      </div>
    </div>
    <div class="flex my-1">
      <b-icon
        icon="credit-card"
        class="has-text-primary has-background-primary-02 rounded-full mt-2"
      />
      <div class="ml-4">
        <p class="font-medium has-text-grey-darker text-md">
          Línea de crédito
        </p>
        <p class="font-bold text-sm">
          <preview v-if="linea.length > 0" :id="linea[0].id" tipo="linea">
            {{ "#" + linea[0].id }}
          </preview>
          <span v-else>No definida</span>
        </p>
      </div>
    </div>
    <div class="flex my-1">
      <b-icon
        icon="user-tie"
        class="has-text-primary has-background-primary-02 rounded-full mt-2"
      />
      <div class="ml-4">
        <p class="font-medium has-text-grey-darker text-md">
          Ejecutivo
        </p>
        <p class="font-bold text-sm">
          <preview :id="ejecutivo.id" tipo="persona"
            >{{ ejecutivo.nombre_completo.split("|")[0] }}
          </preview>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContratoLineaCard",
  props: {
    linea: Array,
    ejecutivo: Object,
  },
  methods: {
    goToLinea() {
      window.location =
        process.env.VUE_APP_CLIENT +
        "/#/administracion/creditos/lineas/ver/" +
        this.linea[0].id;
    },
    goToEjecutivo() {
      window.location =
        process.env.VUE_APP_CLIENT +
        "/#/administracion/personas/ver/perfiles/" +
        this.ejecutivo.id;
    }
  }
};
</script>
