<template>
  <div class="mt-12">
    <div
      class="columns is-multiline"
      :class="{
        'has-background-white-ter has-border-radius pt-4':
          fecha_proyeccion != null &&
          $moment(fecha_proyeccion).toDate() >
            $moment(getSofom.fecha_cierre).toDate()
      }"
    >
      <p
        class="text-3xl column is-12 mb-8 pt-0 text-left font-bold has-text-primary-darker"
        v-if="
          fecha_proyeccion != null &&
            $moment(fecha_proyeccion).toDate() >
              $moment(getSofom.fecha_cierre).toDate()
        "
      >
        Saldos proyectados
      </p>
      <timeline
        :fecha-activa="fecha_activa"
        :fecha-entrega="$moment(disposicion.fecha_entrega).toDate()"
        @fechassetted="getOperaciones"
        @fechaactualsetted="getCreditoPorFecha"
      />
      <div class="column is-2-desktop is-6-tablet" v-if="getCredito != null">
        <div
          class="card has-border-radius shadow-none p-4 py-8 h-full flex flex-col justify-center"
          :class="{
            'bg-gradient-success':
              getCredito.saldos.vencido_exigible <= 0 &&
              getCredito.saldos.vencido_por_traspasar <= 0 &&
              getCredito.saldos.vencido_no_exigible <= 0,
            'bg-gradient-warning':
              getCredito.saldos.vencido_por_traspasar > 0 &&
              getCredito.saldos.vencido_exigible <= 0 &&
              getCredito.saldos.vencido_no_exigible <= 0,
            'bg-gradient-danger':
              getCredito.saldos.vencido_exigible > 0 ||
              getCredito.saldos.vencido_no_exigible > 0,
          }"
        >
          <p
            class="text-2xl font-bold has-text-white"
            v-if="
              getCredito.dias_impago_historico != null &&
                getCredito.dias_impago_historico > 0
            "
          >
            {{ getCredito.dias_impago_historico }} días de atraso
          </p>
          <p
            class="text-2xl has-text-white-always font-bold"
            v-if="
              getCredito.saldos.vencido_exigible > 0 ||
                getCredito.saldos.vencido_no_exigible > 0
            "
          >
            Crédito vencido
          </p>
          <!-- <p class="text-2xl has-text-white-always font-bold"
           v-if="getCredito.saldos.vencido_por_traspasar > 0">
            Crédito en impago
            capital_vencido_por_traspasar
            interes_ordinario_vencido_por_traspasar
            interes_refinanciado_vencido_por_traspasar
          </p> -->
          <div
            v-if="
              getCredito.saldos.capital.valor > 0 && 
              getCredito.saldos.vencido_exigible <= 0 &&
                getCredito.saldos.vencido_no_exigible <= 0
            "
          >
            <p class="text-center p-2 pt-6">
              <b-icon class="text-5xl has-text-white-always" icon="check" />
            </p>
            <p class="text-2xl has-text-white-always font-bold">
              Crédito vigente
            </p>
          </div>
          <p
            class="text-2xl has-text-white-always font-bold"
            v-if="getCredito.saldos.capital.valor == 0"
          >
            Crédito finalizado
          </p>
          <p v-if="getCredito.status_ifrs9 && getCredito.saldos.capital.valor > 0" class="text-2xl has-text-white-always font-bold">
              {{ getCredito.status_ifrs9.nombre }}
          </p>
        </div>
      </div>
      <div class="column is-2-desktop is-6-tablet" v-if="getCredito != null">
        <div
          class="card has-background-white-ter shadow-none has-border-radius p-4 text-left h-full flex flex-col justify-around"
        >
          <div>
            <p class="font-light">Saldo sin atraso</p>
            <p class="has-text-success font-bold text-xl">
              {{
                (getCredito.saldos.vigente +
                  getCredito.saldos.vencido_no_exigible)
                  | currency
                  | hp
              }}
              <span class="font-light">{{ getCredito.moneda.clave }}</span>
            </p>
          </div>
          <div>
            <p class="font-light">Saldo con atraso</p>
            <p class="has-text-danger font-bold text-xl">
              {{
                (getCredito.saldos.vencido_por_traspasar +
                  getCredito.saldos.vencido_exigible +
                  (getCredito.saldos.interes_moratorio ? getCredito.saldos.interes_moratorio.valor : 0) +
                  (getCredito.saldos.iva_interes_moratorio ? getCredito.saldos.iva_interes_moratorio.valor : 0) +
                  (getCredito.saldos.interes_moratorio_provisionado ? getCredito.saldos.interes_moratorio_provisionado.valor : 0) +
                  (getCredito.saldos.iva_interes_moratorio_provisionado ? getCredito.saldos.iva_interes_moratorio_provisionado.valor : 0))
                  | currency
                  | hp
              }}
              <span class="font-light">{{ getCredito.moneda.clave }}</span>
            </p>
          </div>
          <div>
            <p class="font-light">Saldo total</p>
            <p class="has-text-grey-darker font-bold text-xl">
              {{ getCredito.saldos.total | currency | hp }}
              <span class="font-light">{{ getCredito.moneda.clave }}</span>
            </p>
          </div>
          <div class="mt-5">
            <p class="font-light">Deuda al día de hoy</p>
            <p class="has-text-grey-darker font-bold text-xl">
              {{ getCredito.pagar_deuda_hoy | currency | hp }}
              <span class="font-light">{{ getCredito.moneda.clave }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="column is-8-desktop is-8-tablet" v-if="getCredito != null">
        <div
          class="card has-background-white-ter shadow-none has-border-radius p-4 h-full"
        >
          <b-table :data="tabla_montos" v-if="tabla_montos.length > 0">
            <b-table-column v-slot="props">
              <span class="font-bold text-sm ">{{ props.row.tipo }}</span>
            </b-table-column>
            <b-table-column
              field="capital"
              label="Capital"
              class="text-right text-sm"
              header-class="pr-0"
              v-slot="props"
              >{{ props.row.capital | currency | hp }}
              {{ getCredito.moneda.clave }}
              <span v-if="tieneIva">
                (IVA {{ props.row.iva_capital | currency | hp }} )
              </span>
            </b-table-column>
            <b-table-column
              field="interes_ordinario"
              label="Interés ordinario"
              class="text-right text-sm"
              header-class="pr-0"
              v-slot="props"
              >{{ props.row.interes_ordinario | currency | hp }}
              {{ getCredito.moneda.clave }}
              <span v-if="tieneIva">
                (IVA {{ props.row.iva_interes_ordinario | currency | hp }} )
              </span>
            </b-table-column>
            <b-table-column
              field="interes_refinanciado"
              label="Interés refinanciado"
              class="text-right text-sm"
              header-class="pr-0"
              v-slot="props"
              >{{ props.row.interes_refinanciado | currency | hp }}
              {{ getCredito.moneda.clave }}
            </b-table-column>
          </b-table>
          <div v-else class="flex items-center justify-center h-full">
            <p>
              El desglose de saldos solo se mostrará una vez que la fecha del
              sistema sea mayor a la fecha de entrega
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6-desktop is-6-tablet" v-if="getCredito != null">
        <div
          class="card has-background-white-ter shadow-none has-border-radius p-4  text-left flex flex-col justify-between"
        >
          <div class="pb-4">
            <span class="has-text-grey font-bold text-xl"
              >Interés Moratorio Calculado</span
            >
            <div class="is-pulled-right">
              <span class="text-2xl font-bold">
                {{ getCredito.saldos.interes_moratorio ? getCredito.saldos.interes_moratorio.valor : 0 | currency | hp }}
              </span>
              <span class="font-light">{{ getCredito.moneda.clave }}</span>
              <p v-if="tieneIva">
                (IVA
                {{
                  getCredito.saldos.iva_interes_moratorio ? getCredito.saldos.iva_interes_moratorio.valor : 0 | currency | hp
                }}
                )
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6-desktop is-6-tablet" v-if="getCredito != null">
        <div
          class="card has-background-white-ter shadow-none has-border-radius p-4  text-left flex flex-col justify-between"
        >
          <div class="pb-4">
            <span class="has-text-grey font-bold text-xl"
              >Interés Moratorio Provisionado</span
            >
            <div class="is-pulled-right">
              <span class="text-2xl font-bold">
                {{
                  getCredito.saldos.interes_moratorio_provisionado 
                    ? getCredito.saldos.interes_moratorio_provisionado.valor
                    : 0
                    | currency
                    | hp
                }}
              </span>
              <span class="font-light">{{ getCredito.moneda.clave }}</span>
              <p v-if="tieneIva">
                (IVA
                {{
                  getCredito.saldos.iva_interes_moratorio_provisionado
                   ? getCredito.saldos.iva_interes_moratorio_provisionado.valor
                   : 0
                    | currency
                    | hp
                }}
                )
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns" v-if="getCredito != null">
      <div
        class="column is-12"
        v-if="
          getOperacionesDiarias != null &&
            getOperacionesDiarias.registro_operaciones_diarias.length > 0
        "
      >
        <p class="title text-left mt-4">Movimientos financieros</p>
        <b-table
          :data="getOperacionesDiarias.registro_operaciones_diarias"
          paginated
          backend-pagination
          :loading="loading"
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Siguiente Pagina"
          aria-previous-label="Pagina anterior"
          aria-page-label="Pagina"
          aria-current-label="Pagina actual"
          class="text-sm cursor-pointer"
          focusable
        >
          <b-table-column field="fecha" label="Fecha" v-slot="props">{{
            props.row.fecha_operacion | moment("DD-MM-YYYY")
          }}</b-table-column>
          <b-table-column
            field="valor"
            label="Valor"
            class="text-right"
            v-slot="props"
            >{{ props.row.valor_crudo | currency | hp }}</b-table-column
          >
          <!-- <b-table-column field="origen" label="Origen" class="">
              {{ props.row.origen | currency | hp }}
            </b-table-column>-->
          <b-table-column
            field="tipo_operacion"
            label="Tipo de operación"
            class="text-left"
            v-slot="props"
            >{{ props.row.tipo_de_operacion.nombre }}</b-table-column
          >
          <b-table-column
            field="valor_moneda"
            label="Valor moneda"
            class="text-right"
            v-slot="props"
            >{{ props.row.valor_moneda | currency | hp }}</b-table-column
          >
          <b-table-column field="moneda" label="Moneda" v-slot="props">{{
            props.row.moneda.nombre
          }}</b-table-column>
          <b-table-column
            field="instrumento_monetario"
            label="Instrumento Monetario"
            v-slot="props"
            >{{
              props.row.instrumento_monetario
                ? props.row.instrumento_monetario.nombre
                : "N/A"
            }}</b-table-column
          >
          <b-table-column
            field="tasa_base_calculo"
            label="Tasa Base Calculo"
            v-slot="props"
          >
            {{
              props.row.credito_core[0]
                ? props.row.credito_core[0].pivot.valor_tasa != null
                  ? props.row.credito_core[0].pivot.valor_tasa + "%"
                  : "N/A"
                : "N/A"
            }}
          </b-table-column>
          <b-table-column
            field="dias_calculados"
            label="Días calculados"
            v-slot="props"
          >
            {{
              props.row.dias_generados
                ? `${props.row.dias_generados} ${props.row.dias_generados == 1 ? 'día' : 'días'}`
                : "N/A"
            }}
          </b-table-column>
          <b-table-column
            field="monto_base_calculo"
            label="Monto Base Calculo"
            v-slot="props"
          >
            {{
              props.row.credito_core[0]
                ? props.row.credito_core[0].pivot.valor_capital
                : props.row.cuenta_c_p[0]
                ? props.row.cuenta_c_p[0].cantidad
                : "N/A" | currency | hp
            }}
          </b-table-column>
        </b-table>
      </div>
      <div
        v-if="
          getOperacionesDiarias != null &&
            getOperacionesDiarias.registro_operaciones_diarias.length == 0
        "
        class="column is-12"
      >
        <div class="has-background-white-ter has-border-radius p-10 h-80">
          <empty-state
            type="svg"
            imagen="/images/movimientos.svg"
            titulo="No tienes movimientos financieros"
            subtitulo="Intenta introduciendo otra fecha."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timeline from "@/components/Disposicion/Detalle/Timeline";
import EmptyState from "@/components/generals/EmptyState";
import { mapGetters } from "vuex";
import { getLocalParam } from "@/vendors/helpers";

export default {
  name: "Detalle",
  components: {
    Timeline,
    EmptyState
  },
  props: {
    disposicion: Object
  },
  data() {
    return {
      // selected: null,
      fecha_activa: null,
      fecha_proyeccion: null,
      page: 1,
      perPage: getLocalParam("paginador") || 20,
      total: 0,
      loading: false,
      dates: {
        min_date: null,
        max_date: null
      }
    };
  },
  computed: {
    ...mapGetters("disposicion", ["getOperacionesDiarias", "getCredito"]),
    ...mapGetters(["getFechaCierre", "getSofom"]),
    /*
      función encargada de regresar de manera ordenada los saldos de la disposición
    */
    tabla_montos() {
      if (this.getSofom.fecha_cierre < this.getCredito.fecha_entrega) {
        return [];
      }
      return [
        {
          tipo: "Vigente",
          capital: this.getCredito.saldos.capital_vigente ? this.getCredito.saldos.capital_vigente.valor : 0,
          interes_ordinario: this.getCredito.saldos.interes_ordinario_vigente ? this.getCredito.saldos.interes_ordinario_vigente.valor : 0,
          interes_refinanciado: this.getCredito.saldos.interes_refinanciado_vigente ? this.getCredito.saldos.interes_refinanciado_vigente.valor : 0,
          iva_capital: this.getCredito.saldos.iva_capital_vigente ? this.getCredito.saldos.iva_capital_vigente.valor : 0,
          iva_interes_ordinario: this.getCredito.saldos.iva_interes_ordinario_vigente ? this.getCredito.saldos.iva_interes_ordinario_vigente.valor : 0
        },
        {
          tipo: "Impago",
          capital: this.getCredito.saldos.capital_vencido_por_traspasar ? this.getCredito.saldos.capital_vencido_por_traspasar.valor : 0,
          interes_ordinario: this.getCredito.saldos.interes_ordinario_vencido_por_traspasar ? this.getCredito.saldos.interes_ordinario_vencido_por_traspasar.valor : 0,
          interes_refinanciado: this.getCredito.saldos.interes_refinanciado_vencido_por_traspasar ? this.getCredito.saldos.interes_refinanciado_vencido_por_traspasar.valor : 0,
          iva_capital: this.getCredito.saldos.iva_capital_vencido_por_traspasar ? this.getCredito.saldos.iva_capital_vencido_por_traspasar.valor : 0,
          iva_interes_ordinario: this.getCredito.saldos.iva_interes_ordinario_vencido_por_traspasar ? this.getCredito.saldos.iva_interes_ordinario_vencido_por_traspasar.valor : 0
        },
        {
          tipo: "Vencido exigible",
          capital: this.getCredito.saldos.capital_vencido_exigible ? this.getCredito.saldos.capital_vencido_exigible.valor : 0,
          interes_ordinario: this.getCredito.saldos.interes_ordinario_vencido_exigible ? this.getCredito.saldos.interes_ordinario_vencido_exigible.valor : 0,
          interes_refinanciado: this.getCredito.saldos.interes_refinanciado_vencido_exigible ? this.getCredito.saldos.interes_refinanciado_vencido_exigible.valor : 0,
          iva_capital: this.getCredito.saldos.iva_capital_vencido_exigible ? this.getCredito.saldos.iva_capital_vencido_exigible.valor : 0,
          iva_interes_ordinario: this.getCredito.saldos.iva_interes_ordinario_vencido_exigible ? this.getCredito.saldos.iva_interes_ordinario_vencido_exigible.valor : 0
        },
        {
          tipo: "Vencido no exigible",
          capital: this.getCredito.saldos.capital_vencido_no_exigible ? this.getCredito.saldos.capital_vencido_no_exigible.valor : 0,
          interes_ordinario: this.getCredito.saldos.interes_ordinario_vencido_no_exigible ? this.getCredito.saldos.interes_ordinario_vencido_no_exigible.valor : 0,
          interes_refinanciado: this.getCredito.saldos.interes_refinanciado_vencido_no_exigible ? this.getCredito.saldos.interes_refinanciado_vencido_no_exigible.valor : 0,
          iva_capital: this.getCredito.saldos.iva_capital_vencido_no_exigible ? this.getCredito.saldos.iva_capital_vencido_no_exigible.valor : 0,
          iva_interes_ordinario: this.getCredito.saldos.iva_interes_ordinario_vencido_no_exigible ? this.getCredito.saldos.iva_interes_ordinario_vencido_no_exigible.valor : 0
        }
      ];
    }
  },
  // watch: {
  //   selected: function(val) {
  //     this.fecha_activa = this.$moment(val.fecha_operacion).toDate();
  //   }
  // },
  methods: {
    /*
      Obtiene las operaciones de la disposición a una fecha
      @val(Object {min_date: Date, max_date: Date}): 
    */
    getOperaciones(dates) {
      this.loading = true;
      this.dates.min_date = dates.min_date;
      this.dates.max_date = dates.max_date;
      const params = [
        `fecha_operacion_minima=${this.$moment(dates.min_date).format(
          "YYYY-MM-DD"
        )}`,
        "por_pagina=" + this.perPage,
        `fecha_operacion_maxima=${this.$moment(dates.max_date).format(
          "YYYY-MM-DD"
        )}`,
        `pagina=${this.page}`,
        `id=${this.$route.params.idDisposicion}`
      ].join("&");
      this.$store
        .dispatch("disposicion/getOperacionesDiarias", params)
        .then(data => {
          this.total = data.total;
          this.loading = false;
        });
    },
    onPageChange(page) {
      this.page = page;
      this.getOperaciones(this.dates);
    },
    /*
      Obtiene el detalle del crédito a una fecha
      @fecha(Date): fecha a la que se quiere ver el detalle
    */
    getCreditoPorFecha(fecha) {
      this.fecha_proyeccion = this.$moment(fecha).toDate();
      this.$store.dispatch("disposicion/getCredito", {
        id: this.$route.params.idDisposicion,
        fecha: this.$moment(fecha).format("YYYY-MM-DD")
      });
    },
    /*
      Verifica si tiene calculo de iva en la disposicion
    */
    tieneIva() {
      return (
        this.getCredito.iva_interes_moratorio == 1 ||
        this.getCredito.iva_interes_ordinario == 1
      );
    }
  }
};
</script>
