<template>
  <div class="has-background-white-ter has-border-radius p-5 overflow-y-auto">
    <p class="font-bold has-text-grey-darker sm:mb-6 sm:text-xl">
      {{ title }}
    </p>
    <div class="columns is-multiline">
      <div
        class="column is-4 is-narrow"
        v-for="(dato, index) in visibleData"
        :key="index"
      >
        <b-tooltip
          v-if="dato.tooltip"
          :label="dato.tooltip"
          size="is-large"
          multilined
          type="is-dark"
          position="is-right"
        >
          <div class="flex">
            <p class="has-text-grey-light font-light mr-1">{{ dato.title }}</p>
            <b-icon
              v-if="dato.title === 'CAT'"
              class="has-text-primary has-background-primary-02 rounded-full"
              icon="exclamation-circle"
            />
          </div>
        </b-tooltip>
        <p v-else class="has-text-grey-light font-light">{{ dato.title }}</p>
        <div class="has-text-grey-darker" v-html="dato.value" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetalleCard",
  props: {
    title: String,
    data: Array
  },
  computed: {
    visibleData() {
      return this.data.filter(column => {
        return column.hidden != true;
      });
    }
  }
};
</script>
