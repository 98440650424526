var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"w-full text-left",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"modal-card"},[_c('section',{staticClass:"modal-card-head pb-0 has-background-grey-lighter"},[_c('p',{staticClass:"font-bold text-2xl"},[_vm._v("Fondear disposición")]),_c('button',{staticClass:"delete absolute right-0 mr-5",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}})]),_c('section',{staticClass:"modal-card-body has-background-white text-left"},[_c('div',[_c('valid-select',{attrs:{"rules":"required","label":"Fuente de fondeo","placeholder":"Seleccionar...","data":_vm.getFuentesFondeo,"show":"alias","realValue":"id","expanded":true},on:{"input":_vm.getLineas},model:{value:(_vm.form.fuente_fondeo),callback:function ($$v) {_vm.$set(_vm.form, "fuente_fondeo", $$v)},expression:"form.fuente_fondeo"}})],1),_c('div',{staticClass:"mt-3"},[_c('valid-input',{attrs:{"rules":"required","label":"ID fondeador","placeholder":"Introduzca una descripción...","type":"text","expanded":true},model:{value:(_vm.form.identificador_fondeador),callback:function ($$v) {_vm.$set(_vm.form, "identificador_fondeador", $$v)},expression:"form.identificador_fondeador"}}),(_vm.getLineasCliente != null)?_c('ValidAutoComplete',{attrs:{"rules":"required","label":"Línea de crédito","placeholder":"Buscar...","data":_vm.getLineasCliente,"fieldClass":"mt-4","show":"id","expanded":true,"append-to-body":false,"custom":true},scopedSlots:_vm._u([{key:"custom",fn:function(slotProps){return [_c('div',[_c('p',{class:{
                  'has-text-primary-darker':
                    slotProps.option.option.pasiva == 0,
                  'has-text-pasivo': slotProps.option.option.pasiva == 1
                }},[_c('b',[_vm._v("Línea #"+_vm._s(slotProps.option.option.id))]),_vm._v(" - "+_vm._s(slotProps.option.option.producto_financiero ? slotProps.option.option.producto_financiero.alias : "")+" ")]),_c('p',[_vm._v(" Capital autorizado: "+_vm._s(_vm._f("hp")(_vm._f("currency")(slotProps.option.option.capital_autorizado)))+" ")]),_c('p',[_vm._v(" Capital disponible: "),_c('span',{staticClass:"font-bold",class:{
                    'has-text-danger':
                      slotProps.option.option.capital_disponible == 0
                  }},[_vm._v(_vm._s(_vm._f("hp")(_vm._f("currency")(slotProps.option.option.capital_disponible))))])])])]}}],null,true),model:{value:(_vm.form.linea_id),callback:function ($$v) {_vm.$set(_vm.form, "linea_id", $$v)},expression:"form.linea_id"}}):_vm._e(),_c('valid-currency-input',{attrs:{"rules":("currency|max_value:" + (_vm.disposicion.capital)),"label":"Valor del fondeo","placeholder":'Introduzca un valor (max: ' + _vm.disposicion.capital + ')...',"expanded":true},model:{value:(_vm.form.valor_fondeo),callback:function ($$v) {_vm.$set(_vm.form, "valor_fondeo", $$v)},expression:"form.valor_fondeo"}}),_c('b-checkbox',{staticClass:"mt-4",attrs:{"size":"is-medium"},model:{value:(_vm.form.calzar),callback:function ($$v) {_vm.$set(_vm.form, "calzar", $$v)},expression:"form.calzar"}},[_vm._v("Calzar")])],1)]),_c('section',{staticClass:"modal-card-foot justify-end has-background-grey-lighter"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){validate().then(function (result) { return _vm.fondear(result); })}}},[_vm._v("Continuar")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }