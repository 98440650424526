<template>
  <comentarios
    :comentarios="getBitacora"
    tipo="disposicion"
    @comentar="enviarComentario"
    class="p-4"
  />
</template>

<script>
import { mapGetters } from "vuex";
import Comentarios from "@/components/generals/Comentarios";

export default {
  name: "Bitacora",
  components: {
    Comentarios
  },
  methods: {
    /*
      Se agrega comentario a la disposición mediante petición a API
      @comentario (Object): comentario a enviar
    */
    enviarComentario(comentario) {
      comentario.disposicion_id = this.$route.params.idDisposicion;
      this.$store
        .dispatch("disposicion/agregarComentario", comentario)
        .then(() => {
          setTimeout(() => {
            if (document.querySelectorAll("span.w-full > div")) {
              document
                .querySelectorAll("span.w-full > div")
                [
                  document.querySelectorAll("span.w-full > div").length - 1
                ].scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        });
    }
  },
  computed: {
    ...mapGetters("disposicion", ["getBitacora"])
  },
  mounted() {
    this.$store.dispatch(
      "disposicion/getBitacora",
      this.$route.params.idDisposicion
    );
  }
};
</script>
