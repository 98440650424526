<template>
<div class="modal-card modal-content-width">
    <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Configurar bursatilización del crédito</p>
        <button
            type="button"
            class="delete absolute right-0 mr-5"
            @click="$parent.close()"
        />
    </section>
    <section class="modal-card-body has-background-white text-left">
        <div class="flex justify-between mt-5">
            <div>
                <p class="text-lg font-semibold">Bursatilización</p>
            </div>
            <b-switch 
                :value="esBursatilizado"
                @input="(bursatilizado) => bursatilizado ? crearBursatilizacion() : eliminarBursatilizacion()"
                type="is-info">
            </b-switch>
        </div>
    </section>
    <section class="modal-card-foot">
        
        </section>
</div>
</template>

<script>
import DisposicionApi from "@/api/disposicion.api";

export default {
    name: "ModalBursatilizacion",
    props: {
        credito_id: Number,
        esBursatilizado: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        crearBursatilizacion(){
            DisposicionApi.crearCreditoBursatilizado(this.credito_id)
                .then(() => {
                    this.$store.dispatch(
                        "disposicion/getDisposicionDetalle",
                        this.$route.params.idDisposicion
                    );
                })
                .catch(() => this.esBursatilizado = !this.esBursatilizado);
        },
        eliminarBursatilizacion(){ 
            DisposicionApi.borrarCreditoBursatilizado(this.credito_id)
                .then(() => {
                    this.$store.dispatch(
                        "disposicion/getDisposicionDetalle",
                        this.$route.params.idDisposicion
                    );
                })
                .catch(() => this.esBursatilizado = !this.esBursatilizado);
        }
    },
}
</script>