<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Asignar disposición activa a pasiva</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns is-multiline">
          <div class="column is-12">
            <valid-select
              rules="required"
              label="Personas"
              placeholder="Selecciona una persona..."
              v-model="form.persona"
              :expanded="true"
              :data="getAcreditadosSinFondeo"
              show="alias"
              realValue="id"
              @input="loadDisposiciones"
            />
          </div>
          <div class="column is-12">
            <valid-select
              :disabled="!form.persona"
              rules="required"
              label="Disposiciones activas"
              placeholder="Selecciona una disposición..."
              v-model="form.disposicion"
              :expanded="true"
              :data="getCreditosPersona"
              show="creditos_autorizados_id"
              customShow="capital_otorgado"
              realValue="id"
            />
          </div>
          <div v-if="form.disposicion" class="column is-12">
            <valid-currency-input
              :rules="`currency|max_value:${maxCantidad}`"
              label="Valor del fondeo"
              :placeholder="'Introduzca un valor (max: ' + maxCantidad + ')...'"
              v-model="form.valor_fondeo"
              :expanded="true"
            />
          </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(valid => asignarDisposicion(valid))"
        >
          Asignar
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import { mapGetters } from "vuex";
import DisposicionApi from "@/api/disposicion.api";

export default {
  name: "ModalAsignarDisposicionActiva",
  components: {
    ValidationObserver,
    ValidSelect,
    ValidCurrencyInput
  },
  data() {
    return {
      form: {
        persona: null,
        disposicion: null
      }
    };
  },
  methods: {
    async loadDisposiciones(user_id) {
      if (this.form.disposicion) {
        this.form.disposicion = null;
      }
      await this.$store.dispatch(
        "personas/getCreditosConSaldoPersona",
        user_id
      );
    },
    async asignarDisposicion(valid) {
      if (!valid || !this.getCreditosPersona.some(disposicion => disposicion.creditos_entregados_id === this.form.disposicion)) {
        return;
      }
      await DisposicionApi.asignarActivo({
        pasiva_id: this.$route.params.idDisposicion,
        credito_activo_id: this.form.disposicion,
        valor_fondeo: this.form.valor_fondeo,
      });
      this.$parent.close();
    }
  },
  computed: {
    ...mapGetters("personas", ["getAcreditadosSinFondeo", "getCreditosPersona"]),
    maxCantidad() {
      return this.getCreditosPersona.find(credito => credito.id == this.form.disposicion)?.capital_otorgado;
    }
  },
  async mounted() {
    if (!this.getAcreditadosSinFondeo) {
      await this.$store.dispatch("personas/getAcreditadosActivosSinFondeo");
    }
  }
};
</script>
