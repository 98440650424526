<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Cambiar tasa de interes ajeno</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns is-multiline">
          <div class="column is-12">
            <valid-select
              rules="required"
              label="Tasa base"
              placeholder="Selecciona una tasa base..."
              v-model="form.tasas_base_id"
              :expanded="true"
              :data="getTasasBase"
              show="nombre"
              realValue="id"
            />
          </div>
          <div class="column is-12">
            <ValidInput
              rules="required|min_value:0.00"
              label="Cantidad"
              placeholder="Introduzca un porcentaje..."
              v-model="form.cantidad"
              type="number"
              addon
              addonIcon="percentage"
              :expanded="true"
            />
          </div>
          <div class="column is-12">
            <b-checkbox
              size="is-medium"
              v-model="form.iva"
              true-value="1"
              false-value="0"
            >
              IVA
            </b-checkbox>
          </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => cambiarTasa(result))"
        >
          Cambiar tasa
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import ValidInput from "@/components/form/ValidInput";
import ValidSelect from "@/components/form/ValidSelect";

export default {
  name: "ModalTasaInteresAjeno",
  props: {
    disposicion: Object
  },
  data() {
    return {
      form: {
        cantidad: null,
        tasas_base_id: null,
        iva: null,
        id: null
      }
    };
  },
  components: {
    ValidationObserver,
    ValidInput,
    ValidSelect
  },
  computed: {
    ...mapGetters("catalogos", ["getTasasBase"])
  },
  methods: {
    /*
      @result (Boolean): Resultado de las validaciones
    */
    cambiarTasa(result) {
      if (!result) return;
      this.form.id = this.disposicion.id;
      this.$store
        .dispatch("disposicion/cambiarTasaInteresAjeno", this.form)
        .then(() => {
          this.$parent.close();
        });
    }
  },
  created() {
    if (this.disposicion.interes_ordinario_ra) {
      this.form = {
        cantidad: this.disposicion.interes_ordinario_ra.cantidad,
        tasas_base_id: this.disposicion.interes_ordinario_ra.tasas_base_id,
        iva: this.disposicion.interes_ordinario_ra.iva,
        id: this.disposicion.interes_ordinario_ra.simulacion_id
      };
    }
  }
};
</script>
