<template>
<ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
>
    <div class="modal-card modal-content-width">
        <section class="modal-card-head pb-0">
            <p class="font-bold text-2xl">Referencias bancarias de pago</p>
            <button
            type="button"
            class="delete absolute right-0 mr-5"
            @click="$parent.close()"
            />
        </section>
        <section class="modal-card-body has-background-white text-left">
            <div class="p-1">
                <ValidInput
                    rules="required"
                    label="Referencia"
                    placeholder="Ingresa la referencia bancaria..."
                    v-model="referencia_bancaria_pago"
                    inputclass="has-background-white-ter"
                    type="text"
                    :expanded="true"
                >
                    <b-button
                        type="is-primary"
                        class="font-bold rounded-l-none"
                        :disabled="loading"
                        @click="validate().then(result => agregarReferencia(result))"
                    >
                    Agregar
                    </b-button>
                </ValidInput>
                
            </div>
            <section class="flex flex-col mt-8 h-56 overflow-y-auto" v-if="getReferenciasBancarias && getReferenciasBancarias.length > 0">
                <div class="flex justify-between items-center card has-background-white-ter text-left p-3 has-border-radius shadow-none mb-2" v-for="referencia in getReferenciasBancarias" :key="referencia.id">
                    <span class="font-medium truncate">
                        {{ referencia.referencia_bancaria_pago }}
                    </span>
                    <b-button
                        class="bg-transparent text-red-500 border-0"
                        @click="borrarReferencia(referencia.referencia_bancaria_pago)"
                        icon-right="trash" 
                    />
                </div>
            </section>
        </section>
        <section class="modal-card-foot">
            
        </section>
    </div>
</ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

import ValidInput from "@/components/form/ValidInput";

export default {
    name: "ModalReferenciaBancaria",
    components: {
        ValidationObserver,
        ValidInput
    },
    data() {
        return{
            referencia_bancaria_pago: null,
            loading: false,
        }
    },
    computed: {
        ...mapGetters("disposicion", ["getReferenciasBancarias"])
    },
    methods: {
        async agregarReferencia(validated){
            if (! validated) return;

            this.loading = true;

            await this.$store.dispatch(
                'disposicion/crearReferenciaBancariaDisposicion',
                { 
                    disposicion_id: this.$route.params.idDisposicion,
                    referencia_bancaria: this.referencia_bancaria_pago,
                }
            );

            this.loading = false;
        },
        async borrarReferencia(referencia_bancaria){
            this.loading = true;

            await this.$store.dispatch(
                'disposicion/borrarReferenciaBancariaDisposicion',
                { 
                    disposicion_id: this.$route.params.idDisposicion,
                    referencia_bancaria,
                }
            );

            this.loading = false;
        }
    },
    mounted() {
        this.$store.dispatch('disposicion/obtenerReferenciaBancariaDisposicion', this.$route.params.idDisposicion);
    }
}
</script>