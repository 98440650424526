<template>
  <div v-if="getPagos != null">
    <div class="flex justify-between">
      <p class="title text-left mt-4">
        Pagos realizados
      </p>
      <b-button
        icon-left="plus"
        type="is-primary"
        class="mt-3 font-regular"
        tag="router-link"
        :to="{
          name: 'aplicarpago',
          params: {
            tipo: 'disposicion',
            idObjeto: $route.params.idDisposicion
          }
        }"
      >
        Aplicar nuevo pago
      </b-button>
    </div>
    <Tabs
      class="max-w-sm"
      v-model="activeTab"
      :tabs="tabs"
      @change="loadPagos"
    />
    <div v-if="activeTab == 0" class="columns">
      <b-table
        v-if="getPagos.desglose_pagos.length > 0"
        :data="getPagos.desglose_pagos"
        class="text-sm cursor-pointer column is-12 overflow-y-auto"
        :mobile-cards="false"
        focusable
        paginated
        backend-pagination
        :total="getPagos.total"
        :per-page="getPagos.por_pagina"
        @page-change="pagina => cargarPagos(pagina)"
        aria-next-label="Siguiente Pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Pagina"
        aria-current-label="Pagina actual"
      >
        <b-table-column
          field="id"
          subheading="Folio Pago"
          custom-key="id"
          meta-label="Folio"
          sortable
          v-slot="props"
        >
          <span class="has-text-info underline">
            <preview :id="props.row.id" tipo="pago">{{
              props.row.id
            }}</preview>
          </span>
        </b-table-column>
        <b-table-column
          field="fecha_contable.date"
          subheading="Fecha"
          custom-key="fecha_contable"
          sortable
          header-class="px-10"
          v-slot="props"
        >
          {{ props.row.fecha_contable.date | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column
          field="instrumento_monetario.nombre"
          subheading="Instrumento Monetario"
          custom-key="instrumento_monetario"
          sortable
          v-slot="props"
        >
          {{ props.row.instrumento_monetario.nombre }}
        </b-table-column>
        <b-table-column
          field="cantidad_pagada"
          subheading="Monto recibido"
          class="text-right"
          custom-key="cantidad_pagada"
          sortable
          v-slot="props"
        >
          {{ props.row.cantidad_pagada | currency | hp }}
        </b-table-column>
        <b-table-column
          field="moneda.nombre"
          subheading="Moneda"
          custom-key="moneda"
          header-class="px-16"
          sortable
          v-slot="props"
        >
          {{ props.row.moneda.nombre }}
        </b-table-column>
        <!-- CAPITAL -->
        <b-table-column
          label="Capital"
          class="text-right"
          field="desglose_pagos.capital_vigente"
          custom-key="capital_vigente"
          cell-class="border-l-2"
          header-class="border-l-2"
          subheading="Vigente"
          sortable
          v-slot="props"
        >
          {{ props.row.desglose_pagos.capital_vigente | currency | hp }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Impago"
          field="desglose_pagos.capital_vencido_por_traspasar"
          custom-key="capital_impago"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.capital_vencido_por_traspasar
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido exigible"
          field="desglose_pagos.capital_vencido_exigible"
          custom-key="capital_vencido_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.capital_vencido_exigible | currency | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido no exigible"
          field="desglose_pagos.capital_vencido_no_exigible"
          custom-key="capital_vencido_no_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.capital_vencido_no_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <!-- INTERES ORDINARIO -->
        <b-table-column
          label="Interes ordinario"
          class="text-right"
          field="desglose_pagos.interes_ordinario_vigente"
          custom-key="intord_vigente"
          cell-class="border-l-2"
          header-class="border-l-2"
          subheading="Vigente"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vigente | currency | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Impago"
          field="desglose_pagos.interes_ordinario_vencido_por_traspasar"
          custom-key="intord_impago"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vencido_por_traspasar
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido exigible"
          field="desglose_pagos.interes_ordinario_vencido_exigible"
          custom-key="intord_vencido_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vencido_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido no exigible"
          field="desglose_pagos.interes_ordinario_vencido_no_exigible"
          custom-key="intord_vencido_no_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vencido_no_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <!-- INTERES REFINANCIADO -->
        <b-table-column
          label="Interes refinanciado"
          class="text-right"
          field="desglose_pagos.interes_refinanciado_vigente"
          custom-key="intref_vigente"
          cell-class="border-l-2"
          header-class="border-l-2"
          subheading="Vigente"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_refinanciado_vigente
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Impago"
          field="desglose_pagos.interes_refinanciado_vencido_por_traspasar"
          custom-key="intref_impago"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos
              .interes_refinanciado_vencido_por_traspasar
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido exigible"
          field="desglose_pagos.interes_refinanciado_vencido_exigible"
          custom-key="intref_vencido_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_refinanciado_vencido_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido no exigible"
          field="desglose_pagos.interes_refinanciado_vencido_no_exigible"
          custom-key="intref_vencido_no_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_refinanciado_vencido_no_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column v-slot="props">
          <b-button
              v-if="props.row.cancelable && activeTab == 0"
              class="bg-transparent text-red-500 border-0"
              @click="abrirModalEliminarPago(props.row.id, props.row.personas_id)"
              icon-right="trash" />
        </b-table-column>
      </b-table>
      <div v-if="getPagos.desglose_pagos.length == 0" class="column is-12">
        <div class="h-65vh">
          <empty-state
            type="svg"
            :vertical="true"
            imagen="/images/movimientos.svg"
            imgclass="w-2/5 mb-4"
            titulo="No hay pagos realizados"
            subtitulo="Intenta introduciendo otra fecha."
          />
        </div>
      </div>
    </div>
    <div v-if="activeTab == 1" class="columns">
      <b-table
        v-if="getPagosAccesorios && getPagosAccesorios.desglose_pagos.length > 0"
        :data="getPagosAccesorios.desglose_pagos"
        class="text-sm cursor-pointer column is-12 overflow-y-auto"
        :mobile-cards="false"
        focusable
        paginated
        backend-pagination
        :total="getPagosAccesorios.total"
        :per-page="getPagosAccesorios.por_pagina"
        @page-change="pagina => cargarPagosAccesorios(pagina)"
        aria-next-label="Siguiente Pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Pagina"
        aria-current-label="Pagina actual"
      >
        <b-table-column
          field="id"
          subheading="Folio Pago"
          custom-key="id"
          meta-label="Folio"
          sortable
          v-slot="props"
        >
          <span class="has-text-info underline">
            <preview :id="props.row.id" tipo="pago">{{
              props.row.id
            }}</preview>
          </span>
        </b-table-column>
        <b-table-column
          field="fecha_contable.date"
          subheading="Fecha"
          custom-key="fecha_contable"
          sortable
          header-class="px-10"
          v-slot="props"
        >
          {{ props.row.fecha_contable.date | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column
          subheading="Concepto"
          custom-key="concepto"
          sortable
          v-slot="props"
        >
          {{ props.row.cuenta_c_p[0].tipo_de_operacion.nombre }}
        </b-table-column>
        <b-table-column
          field="instrumento_monetario.nombre"
          subheading="Instrumento Monetario"
          custom-key="instrumento_monetario"
          sortable
          v-slot="props"
        >
          {{ props.row.instrumento_monetario.nombre }}
        </b-table-column>
        <b-table-column
          field="cantidad_pagada"
          subheading="Monto recibido"
          class="text-right"
          custom-key="cantidad_pagada"
          sortable
          v-slot="props"
        >
          {{ props.row.cantidad_pagada | currency | hp }}
        </b-table-column>
        <b-table-column
          field="moneda.nombre"
          subheading="Moneda"
          custom-key="moneda"
          header-class="px-16"
          sortable
          v-slot="props"
        >
          {{ props.row.moneda.nombre }}
        </b-table-column>
        <!-- CAPITAL -->
        <b-table-column
          label="Capital"
          class="text-right"
          field="desglose_pagos.capital_vigente"
          custom-key="capital_vigente"
          cell-class="border-l-2"
          header-class="border-l-2"
          subheading="Vigente"
          sortable
          v-slot="props"
        >
          {{ props.row.desglose_pagos.capital_vigente | currency | hp }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Impago"
          field="desglose_pagos.capital_vencido_por_traspasar"
          custom-key="capital_impago"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.capital_vencido_por_traspasar
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido exigible"
          field="desglose_pagos.capital_vencido_exigible"
          custom-key="capital_vencido_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.capital_vencido_exigible | currency | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido no exigible"
          field="desglose_pagos.capital_vencido_no_exigible"
          custom-key="capital_vencido_no_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.capital_vencido_no_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <!-- INTERES ORDINARIO -->
        <b-table-column
          label="Interes ordinario"
          class="text-right"
          field="desglose_pagos.interes_ordinario_vigente"
          custom-key="intord_vigente"
          cell-class="border-l-2"
          header-class="border-l-2"
          subheading="Vigente"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vigente | currency | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Impago"
          field="desglose_pagos.interes_ordinario_vencido_por_traspasar"
          custom-key="intord_impago"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vencido_por_traspasar
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido exigible"
          field="desglose_pagos.interes_ordinario_vencido_exigible"
          custom-key="intord_vencido_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vencido_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido no exigible"
          field="desglose_pagos.interes_ordinario_vencido_no_exigible"
          custom-key="intord_vencido_no_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_ordinario_vencido_no_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <!-- INTERES REFINANCIADO -->
        <b-table-column
          label="Interes refinanciado"
          class="text-right"
          field="desglose_pagos.interes_refinanciado_vigente"
          custom-key="intref_vigente"
          cell-class="border-l-2"
          header-class="border-l-2"
          subheading="Vigente"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_refinanciado_vigente
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Impago"
          field="desglose_pagos.interes_refinanciado_vencido_por_traspasar"
          custom-key="intref_impago"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos
              .interes_refinanciado_vencido_por_traspasar
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido exigible"
          field="desglose_pagos.interes_refinanciado_vencido_exigible"
          custom-key="intref_vencido_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_refinanciado_vencido_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column
          class="text-right"
          subheading="Vencido no exigible"
          field="desglose_pagos.interes_refinanciado_vencido_no_exigible"
          custom-key="intref_vencido_no_exigible"
          sortable
          v-slot="props"
        >
          {{
            props.row.desglose_pagos.interes_refinanciado_vencido_no_exigible
              | currency
              | hp
          }}
        </b-table-column>
        <b-table-column v-slot="props">
          <b-button
              v-if="props.row.cancelable && activeTab == 1"
              class="bg-transparent text-red-500 border-0"
              @click="abrirModalEliminarPago(props.row.id, props.row.personas_id)"
              icon-right="trash" />
        </b-table-column>
      </b-table>
      <div v-if="getPagosAccesorios.desglose_pagos.length == 0" class="column is-12">
        <div class="h-65vh">
          <empty-state
            type="svg"
            :vertical="true"
            imagen="/images/movimientos.svg"
            imgclass="w-2/5 mb-4"
            titulo="No hay pagos realizados"
            subtitulo="Intenta introduciendo otra fecha."
          />
        </div>
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
      v-if="is_modal_eliminar_pago"
      :active.sync="is_modal_eliminar_pago"
      :width="480"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      >
        <modal-eliminar-pago
          class="modal-has-overflow"
          :pagoId="pago_id"
          :personaId="persona_id"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import Tabs from "@/components/generals/Tabs";

import EmptyState from "@/components/generals/EmptyState";
import ModalEliminarPago from "@/components/modals/ModalEliminarPago";
import { mapGetters } from "vuex";

export default {
  name: "Pagos",
  components: {
    EmptyState,
    ModalEliminarPago,
    Tabs,
  },
  data() {
    return {
      selected: null,
      is_modal_eliminar_pago: false,
      pago_id: null,
      persona_id: null,
      activeTab: 0,
    };
  },
  watch: {
    selected: function(val) {
      this.$router.push({ name: "detallepago", params: { idPago: val.id } });
    },
  },
  computed: {
    ...mapGetters("disposicion", ["getPagos", "getPagosAccesorios"]),
    tabs() {
      return [
        {
          label: "De la disposición",
        },
        {
          label: "De accesorios"
        }
      ];
    },
  },
  methods: {
    abrirModalEliminarPago(pago_id, persona_id){
      this.is_modal_eliminar_pago = true;
      this.pago_id = pago_id;
      this.persona_id = persona_id;
      this.$store.dispatch("personas/getDetallesPersona", persona_id);
    },
    cargarPagos(pagina) {
      this.$store.dispatch("disposicion/getPagos",
        { 
          pagina,
          disposicion_id: this.$route.params.idDisposicion,
        }
      );
    },
    cargarPagosAccesorios(pagina) {
      this.$store.dispatch("disposicion/getPagosAccesorios",
        { 
          pagina,
          disposicion_id: this.$route.params.idDisposicion,
        }
      );
    },
    loadPagos(tab) {
      if (tab == 1 && !this.getPagosAccesorios) {
        this.cargarPagosAccesorios(1);
      }
    }
  },
  created() {
    this.$store.dispatch(
      "disposicion/getPagos",
      { disposicion_id: this.$route.params.idDisposicion }
    );

    this.$store.commit("disposicion/SET_PAGOS_ACCESORIOS", null);
  }
};
</script>
