<template>
  <div
    class="card has-border-radius shadow-none has-background-white-ter text-left p-4"
  >
    <div class="columns">
      <div class="column flex items-center">
        <p class="font-bold md:text-xl text-2xl">
          Disposición Pasiva
          <preview
            :id="disposicion.id"
            tipo="credito"
            color="has-text-pasivo"
            border="has-border-pasivo"
            >#{{ idToShow(disposicion) }}
          </preview>
        </p>
      </div>
      <div class="column">
        <p class="font-light has-text-grey-light">Fuente de fondeo</p>
        <p>
          <preview
            :id="disposicion.lineas_credito[0].fuente_de_fondeo.id"
            tipo="persona"
          >
            {{
              disposicion.lineas_credito[0].fuente_de_fondeo
                ? disposicion.lineas_credito[0].fuente_de_fondeo.alias
                : "N/A"
            }}
          </preview>
        </p>
      </div>
      <div class="column">
        <p class="font-light has-text-grey-light">Capital Otorgado</p>
        <p>{{ disposicion.capital_otorgado | currency | hp }}</p>
      </div>
      <div class="column">
        <p class="font-light has-text-grey-light">Capital Insoluto</p>
        <p>{{ disposicion.capital_insoluto | currency | hp }}</p>
      </div>
      <div class="column">
        <p class="font-light has-text-grey-light">Fecha entrega</p>
        <p v-if="disposicion.fecha_entrega">
          {{ disposicion.fecha_entrega | moment("DD-MM-YYYY") }}
        </p>
        <p v-else>N/A</p>
      </div>
      <div class="column">
        <p class="font-light has-text-grey-light">Producto financiero</p>
        <p>{{ disposicion.producto_financiero.alias }}</p>
      </div>
      <div class="column flex items-center justify-start md:justify-end">
        <b-button
          type="is-pasivo"
          tag="a"
          @click="goTo('/#/disposicion/' + disposicion.id)"
          >Ver detalle</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { goTo } from "@/vendors/helpers";

export default {
  name: "DisposicionPasivaCard",
  props: {
    disposicion: Object
  },
  methods:{
    goTo(url){
      goTo(url);
    }
  }
};
</script>
