<template>
  <div
    class="card shadow-none has-border-radius mb-8 cursor-pointer grow p-3"
    @click="verDetalle"
    :class="{
      'has-background-white-ter': tipo == 'sin_supervisar',
      'has-background-grey-lighter': tipo == 'supervisada'
    }"
  >
    <div class="columns">
      <div class="column is-1 text-left md:text-center pb-0 md:pb-3">
        <div class="md:border-r-2 border-gray-400">
          <p
            class="hidden md:block"
            :class="semaforoClass(fechaReal(supervision))"
          >
            {{ fechaReal(supervision) | moment("ddd") }}
          </p>
          <p
            class="md:hidden visible inline-block text-3xl mr-2 font-bold capitalize"
            :class="semaforoClass(fechaReal(supervision))"
          >
            {{ fechaReal(supervision) | moment("dddd") }}
          </p>
          <p
            class="text-4xl font-bold -mt-2 inline-block"
            :class="semaforoClass(fechaReal(supervision))"
          >
            {{ fechaReal(supervision) | moment("DD") }}
          </p>
        </div>
      </div>
      <div
        class="column text-left justify-center flex flex-col pt-0 md:pt-auto"
      >
        <p class="has-text-grey-light text-lg">Supervisor</p>
        <p class="font-normal text-lg">
          {{ supervision.persona.nombre_completo.split("|")[0] }}
        </p>
      </div>
      <div class="column flex items-center">
        <b-button
          :type="tipo == 'sin_supervisar' ? 'is-primary' : 'is-text'"
          icon-left="check-circle"
          expanded
          @click.stop="verRequisitos"
        >
          {{ supervision.requisitos.length }} Requisitos
        </b-button>
      </div>
      <div class="column flex items-center justify-end md:pr-8">
        <b-button
          expanded
          @click.stop="reagendar"
          class="is-light"
          v-if="tipo == 'sin_supervisar'"
          >Reagendar
        </b-button>
        <b-button class="is-primary" v-else>Ver reporte</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupervisionCard",
  props: {
    supervision: Object,
    tipo: String
  },
  methods: {
    reagendar() {
      this.$emit("reagendar", {
        id: this.supervision.id,
        fecha_sugerida: this.supervision.fecha_sugerida
      });
    },
    verDetalle() {
      this.$router.push({
        name: "detallesupervision",
        params: { idSupervision: this.supervision.id }
      });
    },
    verRequisitos() {
      this.$router.push({
        name: "detallesupervision",
        params: { idSupervision: this.supervision.id },
        query: { tab: 1 }
      });
    },
    /*
      Muestra la fecha real de la supervisión ya que cuando ha sido reagendad muestra esa, si no muestra la sugerida
      @supervision (Object): El objeto de la supervisión (respuesta de API)
    */
    fechaReal(supervision) {
      if (supervision.fecha_reagendada) {
        return supervision.fecha_reagendada;
      }
      return supervision.fecha_sugerida;
    },
    //Calcula de que color es el semaforo
    semaforoClass(fecha, title = false) {
      if (this.tipo == "supervisada") {
        return "has-text-grey-darker";
      }
      let fecha_supervision = this.$moment(fecha);
      let diff_dates = fecha_supervision.diff(this.$moment(), "days");
      if (diff_dates <= 0) {
        return title ? "bg-gradient-danger" : "has-text-danger";
      }
      if (diff_dates > 0 && diff_dates <= 7) {
        return title ? "bg-gradient-pasiva" : "has-text-warning";
      } else {
        return title ? "bg-gradient-success" : "has-text-success";
      }
    }
  }
};
</script>
