<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card" style="width: 400px">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Reagendar supervisión</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <p class="has-text-grey-light">Fecha sugerida</p>
        <p class>{{ supervision.fecha_sugerida | moment("DD-MM-YYYY") }}</p>
        <p class="has-text-grey-light mt-3">Fecha reagendada</p>
        <valid-datepicker
          rules="required"
          placeholder="seleccionar día..."
          v-model="fecha_reagendada"
          position="is-bottom-left"
        />
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => reagendar(result))"
          >Asignar fecha reagendada</b-button
        >
      </section>
    </div>
  </ValidationObserver>
</template>
<script>
import ValidDatepicker from "@/components/form/ValidDatepicker";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ModalReagendarSupervision",
  props: {
    supervision: Object
  },
  data() {
    return {
      fecha_reagendada: null
    };
  },
  components: {
    ValidDatepicker,
    ValidationObserver
  },
  methods: {
    /*
      @result (Boolean): Resultado de las validaciones
    */
    reagendar(result) {
      if (!result) {
        return;
      }
      this.$store
        .dispatch("supervision/reagendar", {
          id: this.supervision.id,
          fecha_reagendada: this.$moment(this.fecha_reagendada).format(
            "YYYY-MM-DD"
          )
        })
        .then(() => {
          this.$parent.close();
        });
    }
  }
};
</script>
